export default [
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("@/views/Gallery/galeries.vue"),
    meta: {
      pageTitle: "Gallery",
      breadcrumb: [
        {
          text: "Gallery",
          active: true,
        },
      ],
    },
  },

  {
    path: "/coverpage",
    name: "coverpage",
    component: () => import("@/views/Gallery/CoverPage.vue"),
    meta: {
      pageTitle: "CoverPage",
      breadcrumb: [
        {
          text: "CoverPage",
          active: true,
        },
      ],
    },
  },
];
