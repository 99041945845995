<template>
  <div>
    <div class="pt-2"></div>

    <div class="pt-3">
      <b-card>
        <b-table responsive="sm" :items="items" :fields="fields">
          <template #cell(image)="data">
            <b-img width="100%" :src="appUrl + '/' + data.value"></b-img>
          </template>

          <template #cell(description)="data">
            <div v-if="data.value === 'undefined'">No Description Added</div>
            <div v-else>
              {{ data.value }}
            </div>
          </template>

          <template #cell(action)="data">
            <b-row>
              <b-col md="auto">
                <b-button variant="success" @click="openUpdateModal(data.item)">
                  <feather-icon icon="EditIcon" class="mr-25"
                /></b-button>
              </b-col>
              <b-col md="auto">
                <b-button variant="danger" @click="deleteGallery(data.item.id)">
                  <feather-icon icon="DeleteIcon" class="mr-25"
                /></b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BTable, BButton, BCol, BRow, BImg, BCard } from "bootstrap-vue";
import bookingApi from "@/Api/Modules/booking";
export default {
  name: "bookings",
  components: {
    BButton,
    BTable,
    BButton,
    BCol,
    BRow,
    BImg,
    BCard,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "First Name",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "email",
          label: "Email",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "contactno",
          label: "Contact No",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "noofpeople",
          label: "Number Of People",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "additionaldetails",
          label: "Additional Details/Message",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "price",
          label: "Price",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "season",
          label: "Season",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "hotel_category",
          label: "Hotel Category",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },
      ],
      items: [],
    };
  },

  async created() {
    await this.AllBookings();
  },

  methods: {
    async AllBookings() {
      await this.$vs.loading({
        scale: 0.8,
      });
      const res = await bookingApi.AllBookings();
      this.items = res.data.data;
      this.$vs.loading.close();
    },
  },
};
</script>
