import { api } from "../index";
import notification from "@/ApiConstance/toast";

export default {
  async AllGallery() {
    return await api.get("/allgaleries");
  },
  async AddGalleryImage(payload) {
    return await api.post("/addgalery", payload).then((res) => {
      notification.toast("SuccessFully Added", "success");
      window.location.href = "/gallery";
    });
  },
  async UpdateGalleryImage(payload) {
    return await api.post("/updategalery", payload).then((res) => {
      notification.toast("SuccessFully Updated", "success");
      window.location.href = "/gallery";
    });
  },
  async DeleteGalleryImage(id) {
    return await api.delete(`/deletegalery/${id}`).then((res) => {
      notification.toast("SuccessFully Deleted", "success");
      window.location.href = "/gallery";
    });
  },

  async ChangeCover(payload) {
    return await api.post("/addcover", payload).then((res) => {
      notification.toast("SuccessFully Added", "success");
      window.location.href = "/coverpage";
    });
  },

  async CoverPage() {
    return await api.get("/getcover");
  },
};
