export default [
  {
    path: "/bookings",
    name: "bookings",
    component: () => import("@/views/Bookings/index.vue"),
    meta: {
      pageTitle: "Bookings",
      breadcrumb: [
        {
          text: "Bookings",
          active: true,
        },
      ],
    },
  },
];
