<template>
  <div>
    <b-button variant="primary" @click="show = true">Change Cover</b-button>

    <div class="pt-5">
      <b-img :src="appUrl + '/' + coverimage"></b-img>
    </div>

    <div>
      <b-modal
        ref="changemodal"
        v-model="show"
        hide-footer
        title="Change Cover"
      >
        <b-row>
          <b-col md="12" class="mb-1">
            <b-form-group label="Select Cover Image">
              <validation-Provider
                name="Cover Image"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-file
                  v-model="file"
                  @change="handleFileChange"
                  placeholder="Cover Image"
                ></b-form-file>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-Provider>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-1">
            <b-button @click="submit()" block variant="primary"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import galleryApi from "@/Api/Modules/gallery";
import {
  BButton,
  BImg,
  BModal,
  BCol,
  BRow,
  BCardText,
  BCard,
  BFormRadio,
  BFormGroup,
  BInputGroup,
  BFormTextarea,
  BForm,
  BFormFile,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
export default {
  components: {
    BButton,
    BImg,
    BCol,
    BRow,
    BCardText,
    BModal,
    BCard,
    BFormRadio,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BForm,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    // App Name
    const { appUrl } = $themeConfig.app;
    return {
      appUrl,
    };
  },
  data() {
    return {
      file: [],
      show: false,
      coverimage: "",
    };
  },
  async created() {
    await this.getCover();
  },
  methods: {
    // get cover page

    async getCover() {
      await this.$vs.loading({
        scale: 0.8,
      });
      const res = await galleryApi.CoverPage();

      if (res.data !== '') {
        this.coverimage = res.data.data.image;
      }

      this.$vs.loading.close();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        this.resizeImage(file);
      }
    },
    // resize image
    resizeImage(file) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
      };

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set desired width and height

        canvas.width = 1200;
        canvas.height = 500;
        ctx.drawImage(img, 0, 0, 1280, 794);

        canvas.toBlob((blob) => {
          this.file = new File([blob], file.name, { type: file.type });
        }, file.type);
      };

      reader.readAsDataURL(file);
    },

    // submit data

    async submit() {
      await this.$vs.loading({
        scale: 0.8,
      });
      let form = new FormData();

      form.append("image", this.file);
      await galleryApi
        .ChangeCover(form)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
